import { RulerIcon } from "./BudgetEstimateIcons";
import { Estimate } from "../../../Models/estimatesFilters";
import { useEditDocketSideScreenStore } from "../../../../CoreContent/EditDocketSideScreen/EditDocketSideScreenStore/useEditDocketSideScreenStore";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../../../DoxleGeneralStore/useDoxleThemeStore";
import {
  StyledBudgetRowContainer,
  StyledEditBudgetCustomInput,
  StyledInputEndorment,
} from "./StyledComponentEditDocketBudgetEstimate";
import DoxleIconButton from "../../../../DoxleDesignPattern/DoxleButtons/DoxleIconButton";
import { HiOutlineTrash } from "react-icons/hi2";
import { formatter } from "../../../../Utilities/FunctionUtilities";
import useEditBudgetEstimateRow from "../hooks/useEditBudgetEstimateRow";
import DoxleMultilineTextField from "../../../../DoxleDesignPattern/DoxleTextField/DoxleMultilineTextField";
import { Estimate_Header_List } from "../../Store/useEditEstimateStore";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  item: Estimate;
  itemIndex: number;
}

const EditBudgetEstimateRow = ({ item, itemIndex }: Props) => {
  const [focusedField, setFocusedField] = useState<
    "description" | "amount" | undefined
  >(undefined);
  const { doxleThemeColor, doxleFont, transparentMode, currentTheme } =
    useDoxleThemeStore((state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      transparentMode: state.transparentMode,
      currentTheme: state.currentTheme,
    }));
  const edittedDocket = useEditDocketSideScreenStore(
    (state) => state.edittedDocket,
    shallow
  );
  const docketId = edittedDocket?.docketId;
  const {
    handleClickDeleteEstimate,
    handleUnitCostChange,
    handleQuantityChange,
    handlekeyPress,
    description,
    setDescription,
    quantity,
    unitCost,
    // isUpdatingEstimate,
    isDeletingEstimate,
    unit,
    setUnit,
    handleUpdateItem,
    handleRulerIconClicked,
  } = useEditBudgetEstimateRow({ item });

  return (
    <StyledBudgetRowContainer>
      {Estimate_Header_List.map((header, idx) => (
        <div
          className={header === "Order" ? "order-cell" : "content-cell"}
          key={`row#${idx}#${header}#${item.estimateId}`}
          style={{
            height: "100%",
            width:
              header !== "Description"
                ? header === "Order"
                  ? 40
                  : header === "Amount" || header === "Unit Total"
                  ? "calc(20% - 1px)"
                  : 88
                : undefined,
            flex: header === "Description" ? 1 : undefined,
            backgroundColor:
              header !== "Order" ? "rgba(79, 85, 124, 0.4)" : undefined,
            justifyContent: header === "Description" ? "flex-start" : "center",
          }}
        >
          {header === "Description" && (
            <>
              <DoxleMultilineTextField
                value={description}
                onKeyDown={handlekeyPress}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => {
                  handleUpdateItem();
                  setFocusedField(undefined);
                }}
                onFocus={(e) => {
                  e.currentTarget.select();
                  setFocusedField("description");
                }}
                sx={[
                  {
                    height: "auto !important",
                    backgroundColor: "transparent !important",
                  },
                  focusedField === "description"
                    ? { flex: 1 }
                    : { width: "fit-content !important" },
                ]}
                maxRows={2}
              />
              <DoxleIconButton
                onClick={handleRulerIconClicked}
                buttonSize={30}
                disabled={!Boolean(item.project)}
                iconSource={
                  <RulerIcon
                    fill={item.takeOff ? "#6461FB" : "rgb(175, 175, 199)"}
                  />
                }
              />
            </>
          )}
          {header === "Order" && `${itemIndex + 1}.`}
          {header === "Qty" && (
            <StyledEditBudgetCustomInput
              onBlur={() => handleUpdateItem()}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              id="quantity"
              inputMode="numeric"
              value={quantity}
              onFocus={(e) => e.currentTarget.select()}
              onKeyDown={handlekeyPress}
              onChange={handleQuantityChange}
              multiline={true}
              slotProps={{
                input: {
                  style: {
                    textAlign: "center",
                  },
                },
              }}
            />
          )}
          {header === "Unit" && (
            <StyledEditBudgetCustomInput
              onBlur={() => handleUpdateItem()}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              id="unitFormula"
              value={unit}
              onKeyDown={handlekeyPress}
              onFocus={(e) => e.currentTarget.select()}
              onChange={(e) => setUnit(e.target.value)}
              sx={{
                width: 50,
              }}
              slotProps={{
                input: {
                  style: {
                    textAlign: "center",
                  },
                },
              }}
            />
          )}
          {header === "Amount" && (
            <StyledEditBudgetCustomInput
              onBlur={() => {
                handleUpdateItem();
                setFocusedField(undefined);
              }}
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              id="unitCost"
              value={unitCost}
              inputMode="text"
              onKeyDown={handlekeyPress}
              onChange={handleUnitCostChange}
              onFocus={(e) => {
                e.currentTarget.select();
                setFocusedField("amount");
              }}
              sx={{
                minWidth: "0px !important",
                maxWidth: "100%",
                width: "fit-content !important",
              }}
              slotProps={{
                input: {
                  style: {
                    textAlign: "center",
                    width: "fit-content !important",
                  },
                },
              }}
              startAdornment={
                <StyledInputEndorment
                  $themeColor={doxleThemeColor}
                  $doxleFont={doxleFont}
                  position="start"
                >
                  $
                </StyledInputEndorment>
              }
            />
          )}
          {header === "Unit Total" && (
            <div className="line-cost">
              {formatter.format(parseFloat(item.lineCost.toString()))}
            </div>
          )}
        </div>
      ))}
      {/* <DoxleIconButton
        onClick={handleRulerIconClicked}
        buttonSize={30}
        disabled={!Boolean(item.project)}
        iconSource={
          <RulerIcon fill={item.takeOff ? "#6461FB" : "rgb(175, 175, 199)"} />
        }
      /> */}

      {/* <StyledEstimateIdText
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        {docketId}.{item.index}
      </StyledEstimateIdText> */}

      {/* <StyledEditEstimateStaticWrapper
        $themeColor={doxleThemeColor}
        $doxleFont={doxleFont}
      >
        <div className="quantity-wrapper">
          <StyledEditBudgetCustomInput
            onBlur={() => handleUpdateItem()}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            id="quantity"
            inputMode="numeric"
            value={quantity}
            onFocus={(e) => e.currentTarget.select()}
            onKeyDown={handlekeyPress}
            onChange={handleQuantityChange}
            multiline={true}
            slotProps={{
              input: {
                style: {
                  textAlign: "right",
                },
              },
            }}
          />
          <StyledEditBudgetCustomInput
            onBlur={() => handleUpdateItem()}
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
            id="unitFormula"
            value={unit}
            onKeyDown={handlekeyPress}
            onFocus={(e) => e.currentTarget.select()}
            onChange={(e) => setUnit(e.target.value)}
            sx={{
              width: 50,
            }}
            slotProps={{
              input: {
                style: {
                  textAlign: "center",
                },
              },
            }}
          />
        </div>

        <div className="math-symbol">x</div>

        <StyledEditBudgetCustomInput
          onBlur={() => handleUpdateItem()}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          id="unitCost"
          value={unitCost}
          inputMode="text"
          multiline={true}
          onKeyDown={handlekeyPress}
          onChange={handleUnitCostChange}
          onFocus={(e) => e.currentTarget.select()}
          sx={{
            width: "25%",
          }}
          slotProps={{
            input: {
              style: {
                textAlign: "left",
              },
            },
          }}
          startAdornment={
            <StyledInputEndorment
              $themeColor={doxleThemeColor}
              $doxleFont={doxleFont}
              position="start"
            >
              $
            </StyledInputEndorment>
          }
        />
        <div className="math-symbol">=</div>

        <div className="line-cost">
          {formatter.format(parseFloat(item.lineCost.toString()))}
        </div>
      </StyledEditEstimateStaticWrapper> */}
      {isDeletingEstimate ? (
        <CircularProgress color="error" size={16} />
      ) : (
        <HiOutlineTrash
          color={doxleThemeColor.primaryFontColor}
          size={16}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 4,
          }}
          onClick={handleClickDeleteEstimate}
        />
      )}
    </StyledBudgetRowContainer>
  );
};

export default EditBudgetEstimateRow;
