import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { BaseAPIProps } from "../../Models/baseAPIProps";
import DoxleAPI from "../../Services/DoxleAPI";
import {
  AxiosBackendErrorReturn,
  IApiPaginatedData,
} from "../../Models/axiosReturn";
import {
  NewQA,
  QA,
  QAComment,
  QAImage,
  QAList,
  QAMarkupArrow,
  QAMarkupCircle,
  QAMarkupLabel,
  QAMarkupRectangle,
  QAMarkupStraightLine,
  QAVideo,
  QAWithFirstImg,
  TQAStatus,
} from "../Models/qa";
import useSetQAListQueryData from "../CustomQueryHooks/useSetQAListQueryData";
import useSetQAQueryData from "../CustomQueryHooks/useSetQAQueryData";
import useSetQaCommentQueryData from "../CustomQueryHooks/useSetQaCommentQueryData";
import { Company } from "../../Models/company";
import useSetQaImageQueryData from "../CustomQueryHooks/useSetQaImageQueryData";
import { AxiosResponse, isAxiosError } from "axios";
import useSetQaVideoQueryData from "../CustomQueryHooks/useSetQaVideoQueryData";

//# QA LIST
export interface FilterGetQAListQuery {
  projectId?: string;
  docketId?: string;
  searchText?: string;
  orderBy?: string[];
}

export interface FilterGetQAItemQuery {
  assignee?: string;
  created_by?: string;
  location?: string;
  floor?: string;
  status?: "Working" | "Unattended" | "Completed";
}
interface RetrieveDefectListQueryProps extends BaseAPIProps {
  filter: FilterGetQAListQuery;
  onSuccessCb?: Function;
  enableQuery?: boolean;
}

const useRetrieveQAListQuery = ({
  showNotification,
  company,
  filter,
  onSuccessCb,
  enableQuery,
}: RetrieveDefectListQueryProps) => {
  const { projectId, searchText, docketId, orderBy } = filter;
  const qKey = formQAListQueryKey(filter, company);

  let defectURL = `/defect/?page=1`;
  const getParams: any = {};
  if (company) getParams.company = company.companyId;
  if (projectId) getParams.project = projectId;
  if (docketId) getParams.docket = docketId;
  if (searchText) getParams.search = searchText;
  if (orderBy) orderBy.forEach(order => defectURL += `&order_by=${order}`)
  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = defectURL }) =>
      DoxleAPI.get<IApiPaginatedData<QAList>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined && (enableQuery || true),
      retry: 1,
      staleTime: 5 * 60 * 1000,
      cacheTime: 6 * 60 * 1000,
      refetchOnMount: true,
      refetchInterval: 5 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCb) {
          onSuccessCb(
            res.pages.reduce((acc, data) => {
              return acc.concat(data.data.results);
            }, [] as QAList[])
          );
        }
        // console.log('RESULT DEFECT LIST:', res.data.results);
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect list"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect list"
            );
          }
        }
      },
    }
  );
  return defectQuery;
};

interface AddDefectListqueryProps extends BaseAPIProps {
  filter: FilterGetQAListQuery;
  onSuccessCB?: Function;
  onErrorCB?: Function;
}

const useAddQAListQuery = ({
  showNotification,
  company,
  onSuccessCB,
  filter,
  onErrorCB,
}: AddDefectListqueryProps) => {
  const { handleAddQAList } = useSetQAListQueryData({ filter });

  const addDefectURL = `/defect/`;
  const mutation = useMutation({
    mutationFn: (data: QAList) => {
      const { defectListId, ...rest } = data;
      return DoxleAPI.post(addDefectURL, rest, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "ADDED DEFECT LIST",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );

      if (onSuccessCB) {
        onSuccessCB({ ...result.data, isNew: true } as QAList);
      }
      handleAddQAList({ ...result.data, isNew: true } as QAList);
    },
    onError: (error, variables, context) => {
      if (onErrorCB) onErrorCB();
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Add QA List"
            ).substring(0, 300)
          );
        } else {
          showNotification("Something Wrong!", "error", "Fail To Add QA List");
        }
      }

      console.log("ERROR:", JSON.stringify(error));
    },
  });
  const mutate = (data: QAList) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};

interface DeleteDefectListQueryProps extends BaseAPIProps {
  filter: FilterGetQAListQuery;
  onSuccessCB?: Function;
}
const useDeleteQAListQuery = ({
  showNotification,
  company,
  onSuccessCB,
  filter,
}: DeleteDefectListQueryProps) => {
  const queryClient = useQueryClient();
  const { handleDeleteQAList } = useSetQAListQueryData({ filter });
  const qKey = formQAListQueryKey(filter, company);
  const mutation = useMutation({
    mutationKey: getQAListMutationKey("delete"),
    mutationFn: (defectListId: string) => {
      queryClient.cancelQueries(qKey);
      const addDefectURL = `/defect/${defectListId}/`;
      return DoxleAPI.delete(addDefectURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "DELETED DEFECT LIST",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );
      if (onSuccessCB) onSuccessCB(variables);
      handleDeleteQAList(variables);
    },
    onError: (error: any, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Delete Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Delete Action Timeline"
          );
        }
      }

      console.log("ERROR:", error.toJSON());
    },
  });
  const mutate = (defectListId: string) => mutation.mutate(defectListId);
  return { ...mutation, mutate: mutate };
};
interface UpdateDefectListQueryProps extends BaseAPIProps {
  onSuccessCB?: (newQAList?: QAList) => void;
}
export interface UpdateDefectListParams {
  updateParams: Partial<
    Pick<
      QAList,
      | "defectListTitle"
      | "project"
      | "dueDate"
      | "assignee"
      | "assigneeName"
      | "completed"
      | "docket"
    >
  >;
  qaList: QAList;
}
const useUpdateQAListQuery = ({
  showNotification,
  company,
  onSuccessCB,
  onErrorCb,
}: UpdateDefectListQueryProps) => {
  const mutation = useMutation({
    mutationFn: ({ qaList, updateParams }: UpdateDefectListParams) => {
      const editDefectListURL = `/defect/${qaList.defectListId}/`;
      return DoxleAPI.patch(editDefectListURL, updateParams, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "Successfully Edit QA List",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );

      if (onSuccessCB) {
        onSuccessCB(result.data);
      }
    },
    onError: (error, variables, context) => {
      if (onErrorCb) onErrorCb();
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }

      console.log("ERROR:", (error as any).message);
    },
  });
  const mutate = (updateBody: UpdateDefectListParams) =>
    mutation.mutate(updateBody);
  return { ...mutation, mutate: mutate };
};
interface GetDefectListDetailQueryProps extends BaseAPIProps {
  defectListId: string;
  onSuccessCb?: (qaList: QAList) => void;
  enableQuery?: boolean;
  // filter?: FilterGetQAItemQuery;
}
const useRetrieveQAListDetailQuery = ({
  showNotification,
  company,
  defectListId,
  onSuccessCb,
  enableQuery,
}: // filter,
GetDefectListDetailQueryProps) => {
  const qKey = formDefectListDetailQKey(defectListId, company);
  let defectURL = `/defect/${defectListId}/`;
  const params: any = {};
  // if (filter) {
  //   for (const [key, value] of Object.entries(filter)) {
  //     if (value) params["defect__"+key] = value;
  //   }
  // }
  const defectQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<QAList>(defectURL, {
        headers: {
          "User-Company": company!.companyId,
        },
        params,
      }),
    {
      enabled: company !== undefined && (enableQuery ?? true),
      retry: false,

      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCb) {
          onSuccessCb(res.data);

          // queryClient.setQueryData(qKey, (old: any) => {
          //   console.log('OLD DATA FROM DEFECT LIST DETAIL:');
          //   return old ? [...res.data.results] : old;
          // });
        }
        // console.log('RESULT DEFECT LIST:', res.data.results);
      },
      onError: (error) => {
        if (showNotification)
          if (showNotification) {
            if (isAxiosError<AxiosBackendErrorReturn>(error)) {
              showNotification(
                `${error?.response?.status ?? "ERROR"}: ${
                  error.response?.data.detail ?? "UNKNOWN ERROR"
                }`,
                "error",
                String(
                  error?.response?.data?.detail ?? "Failed to get defect detail"
                ).substring(0, 300)
              );
            } else {
              showNotification(
                "Something Wrong!",
                "error",
                "Failed to get defect detail"
              );
            }
          }
      },
    }
  );
  return defectQuery;
};

export interface UpdateQAListSignature extends BaseAPIProps {
  onSuccessCB?: (newSignatureUrl?: string) => void;
}
interface UpdateQAListSignatureProps {
  qaList: QAList;
  signatureFile: Blob;
}
const useUpdateQAListSignatureQuery = ({
  showNotification,
  company,
  onSuccessCB,
  onErrorCb,
}: UpdateQAListSignature) => {
  const updateImgURL = `/defect/update_defect_list_signature/`;
  const mutation = useMutation({
    mutationFn: (props: UpdateQAListSignatureProps) => {
      const { qaList, signatureFile } = props;
      let formData = new FormData();
      formData.append("defectListId", qaList.defectListId);

      //! The comment part below is where you pass in the signature file, use formData to add the file with the key "files"
      formData.append("files", signatureFile, "Signature.jpeg");
      //!<------
      return DoxleAPI.post<{ url: string }>(updateImgURL, formData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB(result.data.url);
      }
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Signature"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Signature"
          );
        }
      }
      if (onErrorCb) onErrorCb();
      console.log("ERROR:", error);
    },
  });
  const mutate = (props: UpdateQAListSignatureProps) => mutation.mutate(props);
  return { ...mutation, mutate: mutate };
};
interface GenerateDefectListPdfQueryProp extends BaseAPIProps {
  defectListId: string;
  toggleEnableValue?: boolean;
  onSuccessCb?: (pdfPath?: string) => void;
}
const useGenerateDefectListPdfQuery = ({
  showNotification,
  company,
  defectListId,
  onSuccessCb,
  toggleEnableValue,
}: GenerateDefectListPdfQueryProp) => {
  const qKey = formQAListPDFQKey(defectListId);

  let defectURL = `/defect/defectPdf/${defectListId}/`;

  const defectQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<{ result: string }>(defectURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      }),
    {
      enabled: company !== undefined && (toggleEnableValue ?? true),
      retry: 0,
      staleTime: 0,
      refetchInterval: false,
      refetchOnMount: true,

      onSuccess: (res) => {
        if (onSuccessCb && res.data.result) onSuccessCb(res.data.result);
        console.log("RESULT DEFECT PDF SERVER:", res.data);
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Fail To generate PDF"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Fail To Generate PDF"
            );
          }
        }
      },
    }
  );
  return defectQuery;
};

interface CreatePdfForAssignee extends BaseAPIProps {
  onSuccessCb?: (responseBase64: string) => void;
}
interface CreatePdfForAssigneeParam {
  qaListId: string;
  assigneeId: string | null;
  status?: Exclude<TQAStatus, "Working">;
}
const useCreateQAPdfForAssigneeQuery = ({
  showNotification,
  company,

  onSuccessCb,
}: CreatePdfForAssignee) => {
  let defectURL = `/defect/defect_pdf_with_assignee/`;
  let noAssigneeURL = `/defect/defectPdf/`;

  const defectQuery = useMutation(["generate-qa-pdf"], {
    mutationFn: ({
      qaListId,
      assigneeId,
      status,
    }: CreatePdfForAssigneeParam) => {
      let getParams: any = {
        defect_list: qaListId,
        assignee: assigneeId,
      };
      if (status) getParams.status = status;
      return DoxleAPI.get<{ result: string }>(defectURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
        params: getParams,
      });
    },

    onSuccess: (res: AxiosResponse<any>) => {
      if (onSuccessCb && res.data) onSuccessCb(res.data.result);
    },
    onError: (error) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to get defect detail"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to get defect detail"
          );
        }
      }
    },
  });
  return defectQuery;
};
//# QA ITEM
interface RetrieveDefectItemQueryProps extends BaseAPIProps {
  defectListId: string;
  search?: string;
  onSuccessRetrieveCB?: (data?: QAWithFirstImg) => void;
  enableQuery?: boolean;
  filter?: FilterGetQAItemQuery;
}

const useRetrieveQAItemsQuery = ({
  showNotification,
  company,
  defectListId,
  onSuccessRetrieveCB,
  enableQuery,
  search,
  filter,
}: RetrieveDefectItemQueryProps) => {
  const qKey = formQAItemListQKey(defectListId, company, search, filter);

  const getParams: any = {};
  getParams.defect_list = defectListId;
  if (search) getParams.search = search;
  getParams.page_size = 25;
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (value) getParams[key] = value;
    }
  }
  let defectURL = `/defect/defectItems/`;

  const defectQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = defectURL }) =>
      DoxleAPI.get<IApiPaginatedData<QAWithFirstImg>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      getNextPageParam: (prev) => prev.data.next,
      enabled: company !== undefined && (enableQuery ?? true),
      retry: 1,
      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessRetrieveCB) onSuccessRetrieveCB();
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect detail"
            );
          }
        }
      },
    }
  );
  return defectQuery;
};

interface AddDefectItemQueryProps extends BaseAPIProps {
  defectListId: string;
  onSuccessCB?: (newQA?: QA) => void;
  onErrorCB?: Function;
}

const useAddQAItemQuery = ({
  showNotification,
  company,
  onSuccessCB,
  defectListId,
  onErrorCB,
}: AddDefectItemQueryProps) => {
  const { handleAddQAQueryData } = useSetQAQueryData({
    defectListId,
    appendPos: "end",
  });
  const addDefectURL = `/defect/defectItems/`;
  const mutation = useMutation({
    mutationFn: (data: NewQA) => {
      // let formData = new FormData();
      // Object.keys(data).forEach(key => {
      //   if (data[key as keyof Defect] !== null)
      //     formData.append(key, data[key as keyof Defect]);
      // });

      return DoxleAPI.post<QAWithFirstImg>(addDefectURL, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB(result.data);
      }
      handleAddQAQueryData(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To add Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To add Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
      if (onErrorCB) onErrorCB(variables);
    },
  });
  const mutate = (data: NewQA) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};

interface UpdateDefectItemQueryProps extends BaseAPIProps {
  qaItem: QA;
  onSuccessCB?: (edittedQa?: QAWithFirstImg) => void;
}
export interface UpdateDefectItemParams
  extends Partial<
    Pick<
      QA,
      | "assignee"
      | "assigneeName"
      | "description"
      | "dueDate"
      | "status"
      | "room"
      | "floor"
    >
  > {}

const useUpdateQAQuery = ({
  showNotification,
  company,
  qaItem,
  onSuccessCB,
}: UpdateDefectItemQueryProps) => {
  const { handleEditQAQueryData } = useSetQAQueryData({
    defectListId: qaItem.defectList,
  });

  const editDefectItemURL = `/defect/defectItems/${qaItem.defectId}/`;
  const mutation = useMutation({
    mutationFn: (updateBody: UpdateDefectItemParams) => {
      return DoxleAPI.patch<QAWithFirstImg>(editDefectItemURL, updateBody, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "UPDATED DEFECT ITEM",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );

      if (onSuccessCB) {
        onSuccessCB(result.data);
      }
      handleEditQAQueryData(result.data as QA);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To update Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (updateBody: UpdateDefectItemParams) =>
    mutation.mutate(updateBody);
  return { ...mutation, mutate: mutate };
};

export interface DeleteDefectItemQueryProps extends BaseAPIProps {
  onSuccessCB?: Function;
  qaListId: string;
}

const useDeleteQAQuery = ({
  showNotification,
  company,
  onSuccessCB,
  qaListId,
}: DeleteDefectItemQueryProps) => {
  const queryClient = useQueryClient();
  const qKey = formQAItemListQKey(qaListId, company);
  const mutation = useMutation({
    mutationFn: (qaId: string) => {
      queryClient.cancelQueries(qKey);
      const deleteDefectItemURL = `/defect/defectItems/${qaId}/`;
      return DoxleAPI.delete(deleteDefectItemURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      // if (showNotification)
      //   showNotification(
      //     "DELETED QA ITEM",
      //     "success",
      //     "SUCCESSFULLY UPDATED DATA"
      //   );
      if (onSuccessCB) {
        onSuccessCB();
      }
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To delete Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To delete Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (qaId: string) => mutation.mutate(qaId);
  return { ...mutation, mutate: mutate };
};

export interface RetrieveFirstQAItemImage extends BaseAPIProps {
  onSuccessCB?: Function;
  qaItem: QA;
}
const useRetrieveQAItemDetail = ({
  showNotification,
  company,
  qaItem,
  onSuccessCB,
}: RetrieveFirstQAItemImage) => {
  const qKey = formQAItemDetailQKey(qaItem, company);
  console.log("FETCH QA FIRST IMG DATA");
  let url = `/defect/defectItems/${qaItem.defectId}/`;

  const firstImgQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<QAWithFirstImg>(url, {
        headers: {
          "User-Company": company!.companyId,
        },
      }),
    {
      enabled: company !== undefined,
      retry: false,
      staleTime: 0,
      cacheTime: 0.5 * 60 * 1000,
      refetchOnMount: true,
      refetchInterval: false,
      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB();
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect detail"
            );
          }
        }
      },
    }
  );
  return firstImgQuery;
};

//# QA COMMENT
export interface RetrieveQAComment extends BaseAPIProps {
  qaItem: QA;
  onSuccessCB?: Function;
}

const useRetrieveQACommentList = ({
  showNotification,
  company,
  qaItem,
  onSuccessCB,
}: RetrieveQAComment) => {
  const qKey = formQACommentQKey(qaItem, company);

  let url = `/defect/defect_comment/?page=1`;
  let getParams = { defect: qaItem.defectId, page_size: 25 };
  const qaCommentQuery = useInfiniteQuery(
    qKey,
    ({ pageParam = url }) =>
      DoxleAPI.get<IApiPaginatedData<QAComment>>(pageParam, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: company !== undefined,
      retry: false,
      refetchOnWindowFocus: false,

      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB();
      },
      getNextPageParam: (prev) => prev.data.next,
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect comment"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect commment"
            );
          }
        }
      },
    }
  );
  return qaCommentQuery;
};

export interface AddQAComment extends BaseAPIProps {
  qaItem: QA;
  onSuccessCB?: Function;
}
const useAddQACommentQuery = ({
  showNotification,
  company,
  onSuccessCB,
  qaItem,
}: AddQAComment) => {
  const { handleAddQAComment, handleAddQACommentToDefectItemList } =
    useSetQaCommentQueryData({ qaItem });
  const addCommentURL = `/defect/defect_comment/`;
  const mutation = useMutation({
    mutationFn: (data: QAComment) => {
      return DoxleAPI.post(addCommentURL, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB();
      }
      handleAddQAComment(result.data);
      handleAddQACommentToDefectItemList(result.data);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add comment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add comment"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (data: QAComment) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};
export interface PatchQAComment {
  commentId: string;
  commentText?: string;
  isOfficial?: boolean;
}
interface MutateQAComment extends BaseAPIProps {
  qaItem?: QA;
  onSuccessCB?: Function;
}
const useMutateQACommentQuery = ({
  showNotification,
  company,
  onSuccessCB,
  qaItem,
}: MutateQAComment) => {
  const { handlePatchQAComment, handleDeleteQAComment } =
    useSetQaCommentQueryData({ qaItem });
  const destroy = useMutation({
    mutationFn: (commentId: string) => {
      return DoxleAPI.delete(`/defect/defect_comment/${commentId}/`, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB();
      }
      handleDeleteQAComment(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add comment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add comment"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const patch = useMutation({
    mutationFn: ({ commentId, ...data }: PatchQAComment) => {
      return DoxleAPI.patch(`/defect/defect_comment/${commentId}/`, data, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB();
      }
      handlePatchQAComment(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Failed to add comment"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Failed to add comment"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });

  return { destroy, patch };
};

//# QA Image
export interface RetrieveQAImage extends BaseAPIProps {
  qaItem: QA;
  onSuccessCB?: (serverList: QAImage[]) => void;
}
const useRetrieveQAImageList = ({
  showNotification,
  company,
  qaItem,
  onSuccessCB,
}: RetrieveQAImage) => {
  const qKey = formQAImageQKey(qaItem.defectId, company);

  let url = `/defect/defect_image/`;
  let getParams = { defect: qaItem.defectId };
  const qaCommentQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<{ results: QAImage[] }>(url, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),

    {
      enabled: company !== undefined,
      retry: false,

      staleTime: 0,
      cacheTime: 20 * 60 * 1000,
      refetchOnMount: false,
      refetchInterval: 5 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB(res.data.results);
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect detail"
            );
          }
        }
      },
    }
  );
  return qaCommentQuery;
};

export interface AddMultiQAImage extends BaseAPIProps {
  qaItem: QA;
  onSuccessCB?: (returnedData: QAImage[]) => void;
}
interface AddMultiQAImageResponse {
  images: QAImage[];
  errors: any;
}
export interface UploadedQAImage extends Omit<QAImage, "imageId"> {
  file: Blob;
}
const useAddMultiQAImageQuery = ({
  showNotification,
  company,
  onSuccessCB,
  qaItem,
}: AddMultiQAImage) => {
  const queryClient = useQueryClient();
  const { handleAddMultiQAImgQueryData } = useSetQaImageQueryData({
    qaId: qaItem.defectId,
  });
  const addDefectURL = `/defect/multi_add_defect_image/`;
  const mutation = useMutation({
    mutationFn: (dataList: UploadedQAImage[]) => {
      let formData = new FormData();
      formData.append("company", qaItem.company);
      formData.append("defectList", qaItem.defectList);
      if (qaItem.project) formData.append("project", qaItem.project);
      if (qaItem.docket) formData.append("docket", qaItem.docket);
      formData.append("defect", qaItem.defectId);
      console.log("dataList:", dataList);
      //!IMPORTANT: the order adding defect and file need to be exactly same order to not messing up add
      dataList.forEach((data) => {
        const { file, ...rest } = data;
        formData.append("defectImages", JSON.stringify(rest));

        //! the comment part below is where you add the binary file in, use formData to add file with key "files"
        formData.append("files", file, rest.imageName);
        //!<-----
      });

      console.log("formData:", JSON.parse(JSON.stringify(formData)));
      return DoxleAPI.post<AddMultiQAImageResponse>(addDefectURL, formData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onMutate: () => {
      const qKey = formQAImageQKey(qaItem.defectId, company);
      queryClient.cancelQueries(qKey);
    },
    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB(result.data.images);
      }
      handleAddMultiQAImgQueryData(result.data.images);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (data: UploadedQAImage[]) => mutation.mutate(data);
  return { ...mutation, mutate: mutate };
};

export interface BackgroundAddMultiQAImageQueryProps extends BaseAPIProps {
  onSuccessCB?: (returnedData: QAImage[]) => void;
  onErrorCb?: (errorImages?: QAImage[]) => void;
}
interface AddMultiQAImageResponse {
  images: QAImage[];
  errors: any;
}

export interface DeleteQAImageQuery extends BaseAPIProps {
  qaItem: QA;
  onSuccessCb?: (deletedId?: string) => void;
}

const useDeleteQAImageQuery = ({
  showNotification,
  company,
  onSuccessCb,
  qaItem,
}: DeleteQAImageQuery) => {
  const { handleDeleteQAImgQueryData } = useSetQaImageQueryData({
    qaId: qaItem.defectId,
  });
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: getQAImageMutationKey("delete"),
    mutationFn: (deletedId: string) => {
      const deleteURL = `/defect/delete_defect_image/${deletedId}/`;
      const qKey = ["qa-image", company?.companyId, qaItem.defectId];
      queryClient.cancelQueries(qKey);
      return DoxleAPI.delete(deleteURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      if (onSuccessCb) {
        if (variables) onSuccessCb(variables);
      }
      handleDeleteQAImgQueryData(variables);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (deletedId: string) => mutation.mutate(deletedId);
  return { ...mutation, mutate: mutate };
};

export interface UpdateQAImageWithMarkup extends BaseAPIProps {
  onSuccessCB?: (props?: {
    newUrlImg: string;
    prevData: UpdateQAImageWithMarkupProps;
  }) => void;
}
export interface UpdateQAImageWithMarkupProps {
  qaImage: QAImage;
  newImgBlob: Blob;
}
const useUpdateQAImageWithMarkupQuery = ({
  showNotification,
  company,
  onSuccessCB,
}: UpdateQAImageWithMarkup) => {
  const updateImgURL = `/defect/update_image_markup/`;
  const mutation = useMutation({
    mutationFn: (props: UpdateQAImageWithMarkupProps) => {
      const { qaImage, newImgBlob } = props;
      let formData = new FormData();
      formData.append("imageId", qaImage.imageId);
      //! the comment part below is where you add the binary file in, use formData to add file with key "files"
      formData.append("files", newImgBlob);
      //!<-----
      return DoxleAPI.post<{ url: string }>(updateImgURL, formData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      //   if (showNotification)
      //     showNotification(
      //       'ADDED DEFECT ITEM',
      //       'success',
      //       'SUCCESSFULLY UPDATED DATA',
      //     );

      if (onSuccessCB) {
        onSuccessCB({ newUrlImg: result.data.url, prevData: variables });
      }
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }

      console.log("ERROR:", error);
    },
  });
  const mutate = (props: UpdateQAImageWithMarkupProps) =>
    mutation.mutate(props);
  return { ...mutation, mutate: mutate };
};
//# QA Markup
export interface RetrieveQAImageMarkup extends BaseAPIProps {
  qaImage: QAImage;
  onSuccessCB?: (
    serverList: Array<
      | QAMarkupRectangle
      | QAMarkupCircle
      | QAMarkupStraightLine
      | QAMarkupLabel
      | QAMarkupArrow
    >
  ) => void;
  enableQuery?: boolean;
}
const useRetrieveQAImageMarkupList = ({
  showNotification,
  company,
  qaImage,
  onSuccessCB,
  enableQuery,
}: RetrieveQAImageMarkup) => {
  const qKey = formQAImageMarkupQKey(qaImage, company);

  let url = `/defect/defect_image_markup/`;
  let getParams = { defect_image: qaImage.imageId };

  const qaCommentQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get(url, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),
    {
      enabled: company !== undefined && (enableQuery || true),
      retry: false,
      refetchInterval: 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB(res.data);
      },
      onError: (error) => {
        if (showNotification) {
          if (isAxiosError<AxiosBackendErrorReturn>(error)) {
            showNotification(
              `${error?.response?.status ?? "ERROR"}: ${
                error.response?.data.detail ?? "UNKNOWN ERROR"
              }`,
              "error",
              String(
                error?.response?.data?.detail ?? "Failed to get defect detail"
              ).substring(0, 300)
            );
          } else {
            showNotification(
              "Something Wrong!",
              "error",
              "Failed to get defect detail"
            );
          }
        }
      },
    }
  );
  return qaCommentQuery;
};

export interface UpdateQAImageMarkup extends BaseAPIProps {
  onSuccessCB?: (props: {
    successList: Array<
      | QAMarkupRectangle
      | QAMarkupCircle
      | QAMarkupStraightLine
      | QAMarkupLabel
      | QAMarkupArrow
    >;
    qaImage: QAImage;
  }) => void;
}
interface UpdateQAImageMarkupProps {
  qaImage: QAImage;
  markupList: Array<
    | QAMarkupRectangle
    | QAMarkupCircle
    | QAMarkupStraightLine
    | QAMarkupLabel
    | QAMarkupArrow
  >;
}
const useUpdateQAImageMarkupQuery = ({
  showNotification,

  company,
  onSuccessCB,
  onErrorCb,
}: UpdateQAImageMarkup) => {
  const queryClient = useQueryClient();
  const updateImgURL = `/defect/markup_update/`;
  const mutation = useMutation({
    mutationFn: ({ qaImage, markupList }: UpdateQAImageMarkupProps) => {
      let formData = new FormData();
      formData.append("imageId", qaImage.imageId);
      markupList.forEach((data) => {
        formData.append("markups", JSON.stringify(data));
      });

      return DoxleAPI.post(updateImgURL, formData, {
        headers: {
          "User-Company": company?.companyId || "",
          "Content-Type": "multipart/form-data",
        },
      });
    },

    onSuccess: (result: AxiosResponse<any>, variables, context) => {
      if (onSuccessCB) {
        onSuccessCB({
          successList: result.data,
          qaImage: variables.qaImage,
        });
      }
      const qKey = formQAImageMarkupQKey(variables.qaImage, company);
      queryClient.invalidateQueries(qKey);
    },
    onError: (error, variables, context) => {
      if (showNotification) {
        if (isAxiosError<AxiosBackendErrorReturn>(error)) {
          showNotification(
            `${error?.response?.status ?? "ERROR"}: ${
              error.response?.data.detail ?? "UNKNOWN ERROR"
            }`,
            "error",
            String(
              error?.response?.data?.detail ?? "Fail To Update Action Timeline"
            ).substring(0, 300)
          );
        } else {
          showNotification(
            "Something Wrong!",
            "error",
            "Fail To Update Action Timeline"
          );
        }
      }
      if (onErrorCb) onErrorCb();
      console.log("ERROR:", error);
    },
  });
  const mutate = (props: UpdateQAImageMarkupProps) => mutation.mutate(props);
  return { ...mutation, mutate: mutate };
};

//#QQA VIDEO
export interface RetrieveQAVideo extends BaseAPIProps {
  qaId: string;
  onSuccessCB?: (serverList: QAVideo[]) => void;
  enable?: boolean;
}
const useRetrieveQAVideoList = ({
  showNotification,

  company,
  qaId,
  onSuccessCB,
  enable,
}: RetrieveQAVideo) => {
  const qKey = formQAVideoQKey(qaId, company);

  let url = `/defect/file/`;
  let getParams = { defect: qaId };
  const qaCommentQuery = useQuery(
    qKey,
    () =>
      DoxleAPI.get<IApiPaginatedData<QAVideo>>(url, {
        headers: {
          "User-Company": company!.companyId,
        },
        params: getParams,
      }),

    {
      enabled: company !== undefined && (enable ?? false),
      retry: false,

      staleTime: 5 * 60 * 1000,
      cacheTime: 20 * 60 * 1000,
      refetchOnMount: false,
      refetchInterval: 5 * 60 * 1000,
      onSuccess: (res) => {
        if (onSuccessCB) onSuccessCB(res.data.results);
      },
      onError: () => {
        if (showNotification)
          showNotification(
            "SOMETHING WRONG",
            "error",
            "fail to get defect detail"
          );
      },
    }
  );
  return qaCommentQuery;
};

export interface IMutateQAVideo extends BaseAPIProps {
  onSuccessAddVideoCB?: (serverList: QAVideo) => void;
  onSuccessDeleteVideoCB?: (deletedId: string) => void;
}
export interface IAddQAVideoParams {
  qaId: string;
  video: File;
}
const useMutateQAVideoQuery = ({
  showNotification,
  company,
  onSuccessAddVideoCB,
  onSuccessDeleteVideoCB,
}: IMutateQAVideo) => {
  const queryClient = useQueryClient();
  const { handleAddQAVideoData, handleDeleteQAVideoData } =
    useSetQaVideoQueryData({
      appendPos: "start",
    });
  const add = useMutation({
    mutationKey: getQaVideoMutationKey("add"),
    mutationFn: ({ qaId, video }: IAddQAVideoParams) => {
      const formData = new FormData();
      formData.append("defect", qaId);
      formData.append("file", video);
      // queryClient.cancelQueries(qKey);
      return DoxleAPI.post<QAVideo>("/defect/file/", formData, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      handleAddQAVideoData(result.data);
      if (onSuccessAddVideoCB) {
        onSuccessAddVideoCB(result.data);
      }
    },
    onError: (error, variables, context) => {
      if (showNotification)
        showNotification(
          "SOMETHING WRONG",
          "error",
          "fail to get defect detail"
        );
    },
  });
  const destroy = useMutation({
    mutationKey: getQaVideoMutationKey("delete"),
    mutationFn: (item: QAVideo) => {
      const deleteURL = `/defect/file/${item.fileId}/`;
      const qKey = formQAVideoQKey(item.defect, company);
      queryClient.cancelQueries(qKey);
      return DoxleAPI.delete(deleteURL, {
        headers: {
          "User-Company": company?.companyId || "",
        },
      });
    },

    onSuccess: (result, variables, context) => {
      handleDeleteQAVideoData(variables.defect, variables.fileId);
      if (onSuccessDeleteVideoCB) {
        onSuccessDeleteVideoCB(variables.fileId);
      }
    },
    onError: (error, variables, context) => {
      if (showNotification)
        showNotification(
          "SOMETHING WRONG!",
          "error",
          "Fail To Update Action Timeline"
        );

      console.log("ERROR:", error);
    },
  });
  return {
    add: { ...add, mutate: (data: IAddQAVideoParams) => add.mutate(data) },
    destroy: { ...destroy, mutate: (item: QAVideo) => destroy.mutate(item) },
  };
};
//***************** DEFECT QUERY HELPER FUNCTIONS ************* */
//# QA LIST
export const baseQAListQKey = ["defect-list"];
export const formQAListQueryKey = (
  filter: FilterGetQAListQuery,
  company: Company | undefined
) => {
  let qKey = ["defect-list"];
  const { projectId, searchText, docketId } = filter;
  if (company) qKey.push(company.companyId);
  if (projectId) qKey.push(projectId);
  if (docketId) qKey.push(docketId);
  if (searchText) qKey.push(searchText);

  return qKey;
};

export const formDefectListDetailQKey = (
  defectListId: string,
  company: Company | undefined
) => {
  let defectListDetailQKey = ["qa-list-detail"];
  if (company) defectListDetailQKey.push(company.companyId);
  defectListDetailQKey.push(defectListId);
  return defectListDetailQKey;
};

export const getQAListMutationKey = (action: "add" | "edit" | "delete") => [
  `${action}-qa-list`,
];
//#QA ITEM
export const baseQAItemListQKey = ["qa-item-list"];
export const formQAItemListQKey = (
  defectListId: string,
  company: Company | undefined,
  search?: string,
  filter?: FilterGetQAItemQuery
) => {
  let qKey = ["qa-item-list"];
  if (company) qKey.push(company.companyId);
  qKey.push(defectListId);
  if (search) qKey.push(search);
  if (filter?.assignee) qKey.push(filter?.assignee);
  if (filter?.status) qKey.push(filter?.status);
  if (filter?.created_by) qKey.push(filter?.created_by);
  if (filter?.location) qKey.push(filter?.location);
  if (filter?.floor) qKey.push(filter?.floor);

  return qKey;
};

export const formQAItemDetailQKey = (
  qaItem: QA,
  company: Company | undefined
) => {
  const baseQKey = ["qa-item-detail"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(qaItem.defectId);

  return baseQKey;
};

export const getQAItemMutationKey = (action: "add" | "edit" | "delete") => [
  `${action}-qa-item`,
];
//# QA COMMENT
export const baseQACommentListQKey = ["qa-comment"];
export const formQACommentQKey = (qaItem: QA, company: Company | undefined) => {
  let baseQKey = ["qa-comment"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(qaItem.defectId);
  return baseQKey;
};
export const getQACommentMutationKey = (action: "add" | "edit" | "delete") => [
  `${action}-qa-comment`,
];
//# QA IMAGE
export const baseQAImgListQKey = ["qa-image"];
export const formQAImageQKey = (
  qaItemId: string,
  company: Company | undefined
) => {
  let baseQKey = ["qa-image"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(qaItemId);
  return baseQKey;
};
export const getQAImageMutationKey = (action: "add" | "edit" | "delete") => [
  `${action}-qa-image`,
];
//# QA Markup
export const baseQAMarkupListQKey = ["qa-image-markup"];
export const formQAImageMarkupQKey = (
  qaImage: QAImage,
  company: Company | undefined
) => {
  let baseQKey = ["qa-image-markup"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(qaImage.imageId);
  return baseQKey;
};
export const getQAMarkupMutationKey = (action: "add" | "edit" | "delete") => [
  `${action}-qa-markup`,
];
export const addBackgroundQAImageMutatingKey = ["qaImage-add-mutation"];

//#QA PDF
export const formQAListPDFQKey = (qaListId: string) => {
  const qKey = ["defect-list-pdf", qaListId];
  return qKey;
};
//# QA VIDEO
export const baseQAVideoQKey = ["qa-video"];
export const formQAVideoQKey = (
  qaItemId: string,
  company: Company | undefined
) => {
  let baseQKey = ["qa-video"];
  if (company) baseQKey.push(company.companyId);
  baseQKey.push(qaItemId);
  return baseQKey;
};

export const getQaVideoMutationKey = (action: "add" | "delete" | "update") => [
  `${action}-qa-video`,
];
//************************************************************* */
const QAQueryAPI = {
  useRetrieveQAListQuery,
  useAddQAListQuery,
  useDeleteQAListQuery,
  useRetrieveQAItemsQuery,
  useAddQAItemQuery,

  useGenerateDefectListPdfQuery,
  useUpdateQAListQuery,
  useUpdateQAQuery,
  useDeleteQAQuery,
  useRetrieveQAListDetailQuery,
  useRetrieveQAItemDetail,
  useRetrieveQACommentList,
  useMutateQACommentQuery,
  useAddQACommentQuery,
  useRetrieveQAImageList,
  useAddMultiQAImageQuery,
  useDeleteQAImageQuery,
  useRetrieveQAImageMarkupList,
  useUpdateQAImageWithMarkupQuery,
  useUpdateQAImageMarkupQuery,
  useUpdateQAListSignatureQuery,
  useCreateQAPdfForAssigneeQuery,
  useRetrieveQAVideoList,
  useMutateQAVideoQuery,
};

export default QAQueryAPI;
