import { useIsMutating } from "@tanstack/react-query";
import React, { useState } from "react";
import { getQaVideoMutationKey } from "../../../../QueryAPI/qaQueryAPI";
import { QAVideo } from "../../../../Models/qa";

type Props = { videoItem: QAVideo };

interface IQAVideoItem {
  isLoadingImg: boolean;
  isImgError: boolean;
  onLoadImgStart: () => void;
  onLoadImgEnd: () => void;
  onLoadImgError: () => void;
  isDeletingVideo: boolean;
}
const useQAVideoItem = ({ videoItem }: Props): IQAVideoItem => {
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [isImgError, setIsImgError] = useState(false);

  const onLoadImgStart = () => {
    setIsLoadingImg(true);
  };
  const onLoadImgEnd = () => {
    setIsLoadingImg(false);
  };
  const onLoadImgError = () => {
    setIsLoadingImg(false);
    setIsImgError(true);
  };

  const isDeletingVideo =
    useIsMutating({
      mutationKey: getQaVideoMutationKey("delete"),
      predicate: (query) =>
        (query.state.variables as QAVideo).fileId === videoItem.fileId,
    }) > 0;
  return {
    isLoadingImg,
    isImgError,
    onLoadImgStart,
    onLoadImgEnd,
    onLoadImgError,
    isDeletingVideo,
  };
};

export default useQAVideoItem;
